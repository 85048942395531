/*const getBaseUrl = () => {
    let url;
    switch(process.env.NODE_ENV) {
      case 'production':
        url = 'https://ratchu.com';
        break;
      case 'development':
      default:
        url = 'http://localhost:3001/';
    }
  
    return url;
  }
  
  export default axios.create({
    baseURL: getBaseUrl(),
  }); */

  export const baseUrl = process.env.NODE_ENV === 'development' ? 'https://myserver.srutihariharasubramanian.com' : 'https://myserver.srutihariharasubramanian.com';
//export const baseUrl = process.env.NODE_ENV === 'development' ? 'http://localhost:3001' : 'http://localhost:3001';