import axios from 'axios';
import { baseUrl } from './envhttp';
// import { getErpId, getUserId } from './storage';

const erp_id = 1;
//const user_id = getUserId();
axios.defaults.baseURL =baseUrl;

export const listApi =(title)=>{
    return axios.get(title,{ params: { erp_id } })
}

export const getDataApi =(title)=>{
    return axios.get(title,{ params: { erp_id } })
}