import React, { useState } from "react";
import styled from "styled-components";
import { GiHamburgerMenu } from "react-icons/gi";
import { VscChromeClose } from "react-icons/vsc";
import { Link } from "react-router-dom";

function Navbar() {
    const [navbarState, setNavbarState] = useState(false);
    const html = document.querySelector("html");
    html.addEventListener("click", () => setNavbarState(false));

  return (
     <>
      <Nav>
        <div className="brand">
       
          <div className="toggle">
            {navbarState ? (
              <VscChromeClose onClick={() => setNavbarState(false)} />
            ) : (
              <GiHamburgerMenu
                onClick={(e) => {
                  e.stopPropagation();
                  setNavbarState(true);
                }}
              />
            )}
          </div>
        </div>
        <ul className="links">
          <li>
            <Link to="/" className="active">
              home
            </Link>
          </li>
          <li>
            <Link to="/about">about</Link>
          </li>
          <li>
            <Link to="/work">work</Link>
          </li>
          <li>
            <Link to="/press">press</Link>
          </li>
          <li>
            <Link to="/contact">contact</Link>
          </li>
        </ul>
      </Nav>
      <ResponsiveNav state={navbarState} className={navbarState ? "show" : ""}>
        <ul>
          <li>
            <Link
              to="/"
              className="active"
              onClick={() => setNavbarState(false)}
            >
              home
            </Link>
          </li>
          <li>
            <Link to="/about" onClick={() => setNavbarState(false)}>
              about
            </Link>
          </li>
          <li>
            <Link to="/work" onClick={() => setNavbarState(false)}>
              work
            </Link>
          </li>
          <li>
            <Link to="/press" onClick={() => setNavbarState(false)}>
              press
            </Link>
          </li>
          <li>
            <Link to="/contact" onClick={() => setNavbarState(false)}>
              contact
            </Link>
          </li>
        </ul>
      </ResponsiveNav>
    </>
  )
}

export default Navbar;

const Nav = styled.nav`
position: relative;
top: 2.5rem;
z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align:center;
  padding: 0 4vw;
  .brand {
  
    .toggle {
      color: white;
      display: none;
    }
  }
  .links {
    display: flex;
    list-style-type: none;
    gap: 4rem;
    li {
      a {
        color: white;
        font-weight: 400;
        text-decoration: none;
        letter-spacing: 0.1rem;
        transition: 0.3s ease-in-out;
        &:hover {
          color: #f9c74f;
        }
      }
      .active {
        color: #f9c74f;
      }
    }
  }
  @media screen and (min-width: 260px) and (max-width: 1080px) {
    .brand {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      top: 0;
      
      .toggle {
       
        display: block;
      }
    }
    .links {
      display: none;
    }
  }
`;
const ResponsiveNav = styled.div`
  position: fixed;
  right: -100vw;
  top: 0;
  z-index: 10;
  background-color: white;
  height: 100vh;
  width: ${({ state }) => (state ? "60%" : "0%")};
  transition: 0.3s ease-in-out;
  display: flex;
  opacity: 0;
  visibility: hidden;
  ul {
    list-style-type: none;
    width: 100%;
    margin-top: 3rem;
    li {
      width: 100%;
      margin: 1rem 0;
      margin-left: 2rem;
      a {
        text-decoration: none;
        color: #f9c74f;
        font-size: 1.5375rem;
        transition: 0.1s ease-in-out;
        &:hover {
          color: #fc4958;
        }
      }
      &:first-of-type {
        a {
          color: #fc4958;
          font-weight: 900;
        }
      }
    }
  }
`;

