import React, { useState,useEffect  } from "react";
import styled from "styled-components";
import { TitleStyles } from "./ReusableStyle";
//import { aboutDetails } from '../services/aboutDatas';
import AboutTestimonial from './AboutTestimonial';
import HomeContact from './HomeContact';
import AboutHero from './AboutHero';
import parse from 'html-react-parser';
import { listApi } from "../services/appApi";
import { baseUrl } from "../services/envhttp";
//import scrollreveal from "scrollreveal";

function About({title}) {

 /* useEffect(() => {
    const sr = scrollreveal({
      origin: "top",
      distance: "80px",
      duration: 2000,
      reset: false,
    });
    sr.reveal(
      `
        #about,
        section
    `,
      {
        opacity: 0,
        interval: 200,
      }
    );
  }, []); */

  const [query,setQuery]= useState([]);

  useEffect(() => {

    const getItems = async () => {
      try {
        await listApi(title).then((response)=>{
            if(response.data.length>0){
              setQuery(response.data);
              console.log(title);
              console.log(response.data);
            }else{
              setQuery("");
             }	
            })
      } catch (err) {
        console.log(err);
      }
    }
    getItems();
  },[title]); 
  
  return (
    <>
    <AboutHero title="banners" />
    <Section>
                <div className="about">
                    {query.map((about) => {
                        return (
                            <div className="abouts" key={about.id}>
                                <p>{parse(about.desc_1)}</p>
                                <p>{parse(about.desc_2)}</p>
                                <p>{parse(about.desc_3)}</p>
                                <div className="image">
                                <img src={baseUrl + "/public"+ about.img_1} alt=""/>
                                <img src={baseUrl + "/public"+ about.img_2} alt=""/>
                                </div>
                                <div className="iconImage">
                                <img src={baseUrl + "/public"+ about.icon} alt=""/>
                                    <p className="info">{parse(about.icon_desc)}</p>
                                </div>
                                <p>{parse(about.desc_4)}</p>
                                <p>{parse(about.desc_5)}</p>
                                <p>{parse(about.desc_6)}</p>
                            </div>
                        );
                    })}
                </div>

        </Section>
        <AboutTestimonial title="about_testimonials" />
        <HomeContact title="contacts"/>
        </>
  )
}

export default About;

const Section = styled.section`
  ${TitleStyles};
  
  display: flex;
  flex-direction: column;
    gap: 3rem;
    margin-top: 1rem;
    
  .about {
    display: flex;
    flex-direction: column;
    gap: 3rem;
    margin-top: 2rem;
    .abouts {
      display: flex;
      flex-direction: column;
      gap: 0.6rem;
      font-family: 'Arimo';
      justify-content: center;
      align-items: center;
      text-align:left;
      p {
        font-size: 1.0625rem;
        letter-spacing: 0.1rem;
        line-height: 1.8rem;
      }

      .image {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 1.4rem;
        overflow: hidden;
        border-radius: 1rem;
        max-width:100%;
        img {
          width: 100%;
          height:40vh;
          min-height: 35vh;
          object-fit: cover;
        }
      }
      .iconImage{
        img{
          object-fit: cover;
        }
        .info{
          text-align:center;
          font-size: 1.2437rem;
        }
      }
    }
  }

  @media screen and (min-width: 280px) and (max-width: 720px) {
    margin: 0 5vw;
  }
  @media screen and (min-width: 720px) and (max-width: 1080px) {
    margin: 0 10vw;
  }
`;