import React, {  useState,useEffect } from "react";
import styled from "styled-components";
import { TitleStyles } from "./ReusableStyle";
import PressHero from './PressHero';
import HomeContact from './HomeContact';
import { FaCaretRight } from 'react-icons/fa';
//import scrollreveal from "scrollreveal";
import { Image } from 'antd';
import { listApi } from "../services/appApi";
import { baseUrl } from "../services/envhttp";
//import ImageGallery from "react-image-gallery";

function Press({title}) {
  const [visible, setVisible] = useState(false);
  //const [scaleStep, setScaleStep] = useState(0.5);
  const [imgSrc, setImage] = useState(null);
  const [query,setQuery]= useState([]);
 // const [query, setQuery] = useState(pressDetails);

  const handleImgView = (img) => {
    setVisible(true);
    setImage(img);
  }

  useEffect(() => {
    const getItems = async () => {
      try {
        await listApi(title).then((response)=>{
            if(response.data.length>0){
              setQuery(response.data);
             
            }else{
              setQuery("");
             }	
            })
      } catch (err) {
        console.log(err);
      }
    }
    getItems();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

 /* const handleGenre = (e) => {
    setQuery(pressDetails.filter(press => press.info.toLowerCase().includes(e.target.value.toLowerCase())));
  }

  const handleYear = (e) => {
    setQuery(pressDetails.filter(press => press.info.toLowerCase().includes(e.target.value.toLowerCase())));
  }

  const handleTitle = (e) => {
    setQuery(pressDetails.filter(press => press.title.toLowerCase().includes(e.target.value.toLowerCase())));
  }

  useEffect(() => {
    const sr = scrollreveal({
      origin: "top",
      distance: "80px",
      duration: 2000,
      reset: false,
    });
    sr.reveal(
      `
        #press,
        section
    `,
      {
        opacity: 0,
        interval: 200,
      }
    );
  }, []); */

  return (
    <>
      <PressHero title="banners" />
      <Section>
        
        <div className="press">
          {query.map((press) => {
            return (
              <div key={press.id}>
                <div className="image">
                  <img src={baseUrl + "/public"+ press.img} t="400px"
           onClick={() => handleImgView(baseUrl + "/public"+ press.img)} alt=""/>
                  <Image
                    width="100%"
                    height="100%"
                    style={{
                      display: 'none',
                    }}
                    preview={{
                      visible,
                      src: imgSrc,
                      onVisibleChange: (value) => {
                        setVisible(value);
                      },
                    }}
                  />  
                </div>
                <button onClick={() => handleImgView(baseUrl + "/public"+ press.img)}>VIEW FULL ARTICLE <FaCaretRight style={{ color: "#e85d04" }} /></button>
              </div>
            );
          })}
        </div>
      </Section>
      <HomeContact title="contacts"/>
    </>
  )
}

export default Press;

const Section = styled.section`
  ${TitleStyles};

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .press {
      display: grid;
      grid-template-columns:  repeat(2, 1fr);
      gap: 2.5rem;
      text-align: left;
      margin-bottom: 2rem;

      .image {
          display: inline-block;
          overflow: hidden;
          border-radius: 1rem;
          object-fit: cover;
          height: 50vh;
          width: 100%;
          
          img {
            width: 100%;
            cursor: pointer;
          }
      }
      button {
          border: none;
          padding: 0.5rem 2rem;
          font-size: 1.1rem;
          font-weight: 600;
          color: black;
          margin-top: 0.8rem;
          border-radius: 2rem;
          transition: 0.5s ease-in-out;
          cursor: pointer;
          background:none;
          text-decoration: none;
          width: fit-content;
          text-transform: uppercase;
          letter-spacing: 0.2rem;
          line-height: 2.6rem;
       }
    }

  @media screen and (min-width: 280px) and (max-width: 720px) {
    margin: 0 5vw;
    .search{
      span{
        display: none;
      }
    }
    .press {
      grid-template-columns: repeat(1, 1fr);

      .image{
        height:40vh;
      }

      button {
        font-size: 0.9rem;
        font-weight: 600;
        letter-spacing: 0.1rem;
        line-height: 1rem;
     }
    }
  }
  @media screen and (min-width: 720px) and (max-width: 1080px) {
    margin: 0 10vw;
    .search{
      span{
        display: none;
      }
    }
    .press {
      grid-template-columns: repeat(2, 1fr);
      .image{
        height:40vh;
      }
      button {
        font-size: 1rem;
        font-weight: 600;
        letter-spacing: 0.1rem;
        line-height: 1rem;
     }
    }
  }
`;