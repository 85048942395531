import React, { useState,useEffect  } from "react";
import styled from "styled-components";
import { listApi } from "../services/appApi";
import { baseUrl } from "../services/envhttp";
// import { aboutsHero } from '../services/heroDatas';

function AboutHero({title}) {

  const [query,setQuery]= useState([]);

 

  useEffect(() => {

    const getItems = async () => {
      try {
        await listApi(title).then((response)=>{
            if(response.data.length>0){
              setQuery(response.data);
            }else{
              setQuery("");
             }	
            })
      } catch (err) {
        console.log(err);
      }
    }

    getItems();
  },[title]);

  return (
    <Section id="about">
    { query.map((hero)=>(
       hero.id===2?
     <div key={hero.id}>
 <div className="background">
 <img src={baseUrl + "/public"+ hero.img} alt={hero.name}/>
       </div>
 
   <div className="info">
     <p> {hero.name}</p>
   </div>

 </div> :''
   ))}      
</Section>
  )
}

export default AboutHero;
const Section = styled.div`
    display: flex;
    height:100%;
    position: relative;
    top:-20px;
    align-items: center;
  .background {
    height: auto;
    img {
      max-width: 100%;
      height: auto;
      object-fit: cover;
      filter: brightness(70%);
    }
  }
  
  .info {
    position: absolute;
    top:0;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
    text-align: center;
    p {
      color: white;
      font-family: 'Lora', serif;
      font-size: 2.0688rem;
      text-decoration: underline;
    }
  }
`;