import React, { useState,useEffect  } from "react";
import styled from "styled-components";
//import { aboutDetails } from '../services/homeDatas';
//import { imageZoomEffect } from "./ReusableStyle";
import { FaCaretRight } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';
import { listApi } from "../services/appApi";
import { baseUrl } from "../services/envhttp";
import parse from 'html-react-parser';

function HomeAbout({title}) {
  let navigate = useNavigate();
  const handleClick = (e) => navigate('/about');
  const [query,setQuery]= useState([]);

  useEffect(() => {
    const getItems = async () => {
      try {
        await listApi(title).then((response)=>{
            if(response.data.length>0){
              setQuery(response.data);
            }else{
              setQuery("");
             }	
            })
      } catch (err) {
        console.log(err);
      }
    }
    getItems();
  },[title]); 

  return (
      <Section>
        <div className="head">
          <h2>
            <span>about</span>
          </h2>
        </div>
        {query.map((about) => {
          return (
            <div className='abouts'>
            <div className="about" key={about.id}>
              <p>{parse(about.desc_1)}</p>
              <div className="image">
              <img src={baseUrl + "/public"+ about.img_1} alt=""/>
              <img src={baseUrl + "/public"+ about.img_2} alt=""/>
              </div>
              <p>{parse(about.desc_2)}</p>
            </div>
            <button onClick={handleClick}>Read More <FaCaretRight style={{ color: "rgb(182, 132, 67)" }} /> </button>
            </div>
          );
        })}

      </Section>
  )
}

export default HomeAbout;

const Section = styled.section`
 
    display: grid;
    grid-template-columns: min-content 1fr;
    margin-top: 1rem;
   gap:6rem;
 .head{
  justify-content: center;
    align-items: center;
    font-family: 'Josefin Sans', sans-serif;
   text-align:left;
   span {
    color:  rgb(182, 132, 67);
    font-weight:800;
  }
 }
 .abouts{
  text-align:left;
  justify-content: left;
  align-items: left;

  .about {
    display: flex;
    flex-direction: column;
    gap: 0.6rem;
    font-family: 'Arimo';
    text-align:left;
    justify-content: center;
    align-items: center;
    p {
      font-size: 1.0625rem;
      letter-spacing: 0.1rem;
      line-height: 1.8rem;
    }
  
    .image {
      display:grid;
      grid-template-columns: repeat(2,1fr);
      gap: 1.4rem;
      overflow: hidden;
      border-radius: 1rem;
      max-width:100%;
  
      img {
        width: 100%;
        height:40vh;
        min-height: 35vh;
        object-fit: cover;
      }
    }
  }
  button {
    border: none;
    padding: 0.5rem 0.5rem;
    margin-top: 0.7rem;
    font-size: 1.1rem;
    font-weight: 600;
    transition: 0.5s ease-in-out;
    cursor: pointer;
    width: fit-content;
    letter-spacing: 0.2rem;
    line-height: 1.6rem;
    background: none;
    text-transform: uppercase;
  }
 }
    

  @media screen and (min-width: 280px) and (max-width: 720px) {
    margin: 0 5vw;
    gap:0.1rem;
    grid-template-columns: repeat(1, 1fr);
    .about {
      .image {
        grid-template-columns: 49% 49%;
      }
    }
  }
  @media screen and (min-width: 720px) and (max-width: 1080px) {
    margin: 0 10vw;
    gap:0.1rem;
      grid-template-columns: repeat(1, 1fr);
      .about {
      .image {
        grid-template-columns: 49% 49%;
        }
      }
  }
`;