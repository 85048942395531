import React, { useState,useEffect  } from "react";
import styled from "styled-components";
import { listApi } from "../services/appApi";
import { baseUrl } from "../services/envhttp";
// import { homeHero } from '../services/heroDatas';

function Hero({title}) {

  const [query,setQuery]= useState([]);

  useEffect(() => {
    const getItems = async () => {
      try {
        await listApi(title).then((response)=>{
            if(response.data.length>0){
              setQuery(response.data);
            }else{
              setQuery("");
             }	
            })
      } catch (err) {
        console.log(err);
      }
    }
    getItems();
  },[title]); 

  return (
    <Section id="home">
         { query.map((hero)=>(
          hero.id===1?
          <div key={hero.id}>
      <div className="background">
      <img src={baseUrl + "/public"+ hero.img} alt={hero.name}/>
            </div>
      <div className="content">
        <div className="info">
          <p> {hero.name}</p>
        </div>
      </div>
      </div> 
       :'' ))}      
    </Section>
  )
}

export default Hero;

const Section = styled.div`
    display: flex;
    height:100%; 
    position: relative;
    top:-20px;
    align-items: center;
  .background {
    height: auto;
    img {
      max-width: 100%;
      height: auto;
      object-fit: cover;
      filter: brightness(70%);
    }
  }
  .content {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
  
    .info {
      position: relative;
      top: 11rem;
      right: 28rem;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    
      p {
        font-family: 'Arimo', sans-serif;
        color: white;
        letter-spacing: 0.01rem;
        font-size: 2.0688rem;
      }
    }
  }
  @media screen and (min-width: 280px) and (max-width: 720px) {
    .content {
      .info {
        top:2rem;
        right: 0.5rem;
        p {
        font-size: 0.9rem;
        letter-spacing: 0rem;
        }
      }
    }
  }

  @media screen and (min-width: 720px) and (max-width: 1080px) {
    .content {
      .info {
        top:5rem;
        right: 6rem;
        p {
          font-size: 1.8rem;
          letter-spacing: 0rem;
        }
      }
    }
  }
`;

