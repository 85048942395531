import React, { useState,useEffect } from "react";
import styled from "styled-components";
import TESTIMONIALS from "../assets/icons/TESTIMONIALS.png";
// import { aboutTestimonials } from '../services/aboutDatas';
import scrollreveal from "scrollreveal";
import { listApi } from "../services/appApi";
import parse from 'html-react-parser';

function AboutTestimonial({title}) {

  const [query,setQuery]= useState([]);

  

    useEffect(() => {
      const getItems = async () => {
        try {
          await listApi(title).then((response)=>{
              if(response.data.length>0){
                setQuery(response.data);
              }else{
                setQuery("");
               }	
              })
        } catch (err) {
          console.log(err);
        }
      }
      getItems();
        const sr = scrollreveal({
          origin: "top",
          distance: "80px",
          duration: 2000,
          reset: false,
        });
        sr.reveal(
          `
            #testimonial
        `,
          {
            opacity: 0,
            interval: 200,
          }
        );
      },[title]);

    return (
        <Section id="testimonial">
            <div className="title">
                <div className="iconImage">
                    <img src={TESTIMONIALS} alt="" />
                </div>
                <h1>
                    TESTIMONIALS
                </h1>
            </div>
            {query.map((testmonial) => {
                return (
                    <div className="details">
                         <p className='testimonialDesc'> {parse(testmonial.desc_1)} </p>

                      
                         <p> <b>{testmonial.name} ,</b>    {testmonial.dest} </p>
                       
                         <div style={{background: 'black',height: '3px',width:'100%'}}></div>
                    </div>
                );
            })}
        </Section>
    )
}

export default AboutTestimonial;

const Section = styled.div`

    display: flex;
    flex-direction: column;
    gap: 3rem;
    margin-top: 3rem;
    padding-bottom: 3rem;
    background-color:lightgray;   
    justify-content: center;
   align-items: center;

    .title{
        display: flex;
       flex-direction: column;
       letter-spacing: 0.1rem;
       line-height: 1.8rem;
       gap: 0.6rem;  
       justify-content: center;
       align-items: center;
       text-align:center;
       h1{
        font-family: 'Josefin Sans';
      font-size: 1.5375rem;
      font-weight:700;
       }
    }

    .details {
        display: flex;
        flex-direction: column;
        gap: 1.1rem;
        padding: 0 5vw;
        margin:0 5rem 0 5rem;
        justify-content: center;
        align-items: center;
        .testimonialDesc{
          
            font-size: 1.0625rem;
            letter-spacing: 0.1rem;
           line-height: 1.8rem;
           font-weight: unset;
           color:#332d2d;
        }
        p{
          font-family: 'Josefin Sans';
            font-size: 1.0625rem;
            letter-spacing: 0.1rem;
           line-height: 1.8rem;
        }
    }

    @media screen and (min-width: 280px) and (max-width: 720px) {
        align-items:unset;
    
        .details {
          max-width:100%;
          margin:0;
          gap: 1.1rem;
        }
      }
      @media screen and (min-width: 720px) and (max-width: 1080px) {
        align-items:unset;
    
        .details {
          max-width:100%;
          margin:0;
          gap: 1.1rem;
        }
       }
`;