import React, { useState,useRef,useEffect  } from "react";
import styled from "styled-components";
import { TitleStyles } from "./ReusableStyle";
import WorkHero from './WorkHero';
import HomeContact from './HomeContact';
//import scrollreveal from "scrollreveal";
import parse from 'html-react-parser';
import ReactPlayer from 'react-player';
import { listApi } from "../services/appApi";
import { baseUrl } from "../services/envhttp";
//import axios from 'axios';
//import { listApi } from "../services/appApi";

function Works({title}) {
  const playerRef = useRef(null);
 // const dispatch = useDispatch();
  const [query,setQuery]= useState([]);
  const [work,setWork]= useState([]);
 // const [content,setContent]=useState("");

  const handleGenre = (e) => {
    setQuery(work.filter(home=>home.info.toLowerCase().includes(e.target.value.toLowerCase()))); 
  }

  const handleYear = (e) => {
    setQuery(work.filter(home=>home.info.toLowerCase().includes(e.target.value.toLowerCase()))); 
  }

  const handleTitle = (e) => {
    setQuery(work.filter(home=>home.title.toLowerCase().includes(e.target.value.toLowerCase()))); 
  }

  useEffect(() => {
    const getItems = async () => {
      try {
        await listApi(title).then((response)=>{
            if(response.data.length>0){
              setQuery(response.data);
              setWork(response.data);
            }else{
              setQuery("");
             }	
            })
      } catch (err) {
        console.log(err);
      }
    }
    getItems();
  }, [title]); // eslint-disable-line react-hooks/exhaustive-deps

 /* useEffect(() => {
    const sr = scrollreveal({
      origin: "top",
      distance: "80px",
      duration: 2000,
      reset: false,
    });
    sr.reveal(
      `
        #work,
        section
    `,
      {
        opacity: 0,
        interval: 200,
      }
    );
  }, []); */

  return (
    <>
    <WorkHero title="banners" />
    <Section>
    <div className="search">
        <input type="text" placeholder="Search by Genre" onChange={handleGenre} /> <span>|</span>
        <input type="text" placeholder="Search by Year"  onChange={handleYear} /> <span>|</span>
        <input type="text" placeholder="Search by Title"  onChange={handleTitle} />
      </div>
      {query.map((work) => {
          return (
            <div className="work" key={work.id}>
              <div className="works">
              <div className="image">
                <ReactPlayer ref={playerRef} url={work.url}  playing light={baseUrl + "/public"+ work.img} controls={true} fluid={false} width="100%" />
              </div>
              <div className="workDesc">
                <img src={baseUrl + "/public"+ work.icon} alt="" />
                <h5>{parse(work.info)}</h5>
                <h3>{parse(work.title)}</h3>
                <h4>{parse(work.sub)}</h4>
                <p>Produced by: <b>{work.producedby}</b></p>
                <p>Languages: <b>{work.language}</b></p>
                <p> {work.imdb?'IMDB :':''} {work.imdb?parse(work.imdb):''}</p>
                <p> {work.youtube?'YouTube :':''} {work.youtube?parse(work.youtube):''}</p>
                <p> {work.trailer?'TRAILER :':''} {work.trailer?parse(work.trailer):''}</p>
              </div>
              </div>
              <h1>DESCRIPTION:</h1>
              <p>{parse(work.desc_1)}</p>
            </div>
          );
        })}
        <h4>Recognition for A Far Afternoon:</h4>
        <ul>
        <li>Won the Rajat Kamal award for Best Art/Cultural Film at the 63rd National Film Awards | India</li>
        <li>Won the Rajat Kamal award for Best Music in the Non-feature category at the 63rd National Film Awards | India</li>
        <li>Nominated for Best Documentary at the NewYork Indian Film Festival | USA</li>
        <li>Nominated for Best Documentary at the 13th Indian Film Festival | Stuttgart, Germany</li>
        <li>Official selection for the 3rd Cinema Indien | Stockholm, Sweden</li>
        <li>Official Selection for the 5th Delhi International Film Festival | India</li>
        <li>Official Selection for the All Lights International Film Festival | India</li>
      </ul>
    </Section>
    <HomeContact title="contacts" />
    </>
  )
}

export default Works;

const Section = styled.section`
  ${TitleStyles};

      display: flex;
      flex-direction: column;
      justify-content: left;
      text-align:left;
      margin-top: 3rem;
       margin-bottom: 3rem;
      gap: 1.1rem;
      .search{
        display:flex;
        flex-wrap: wrap;
        margin-bottom: 2rem;
        gap: 1.6rem;
        justify-content: center;
        align-items: center;
        font-size: 1.1375rem;
        input{
          font-size: 1.1375rem;
          padding: 5px;
      }
      span{
        dispay:block;
      }
      }
      .work{
        display: flex;
        flex-direction: column;
        gap: 1.6rem;
        margin-top: 2rem;
        .works{
          display: grid;
          grid-template-columns:  50% 50%;
          gap: 2rem;
    
            .image {
              display: grid;
              max-width:100%;
            }
      
          .workDesc {
            display: flex;
            flex-direction: column;
            gap: 0.6rem;
            align-items: left;
            max-width: 100%;
            overflow: hidden;
            overflow-wrap: break-word;
            img {
              width:10vh;
              min-width: 8vh;
            }
            
            h3 {
              text-align: left;
             font-size: 1.1375rem;
             letter-spacing: 0.1rem;
             line-height: 2.1rem;
            }
            h4 {
              text-align: left;
              font-family: 'Arimo';
              letter-spacing: 0.1rem;
              line-height: 1.1rem;
              font-size: 0.925rem;
            }
            h5 {
              text-align: left;
              font-size: 0.6875rem;
              letter-spacing: 0.3rem;
              line-height: 2.3rem;
              color:  rgb(182, 132, 67);
            }
            p {
              overflow-wrap: break-word;
              text-align: left;
              font-family: 'Arimo';
              line-height: 1.1rem;
              letter-spacing: 0.1rem;
              font-size: 0.925rem;
            }
        }   
      }
      h1{
        font-size: 1.0437rem;
        line-height: 0.01rem;
        letter-spacing: 0.1rem;
      }
      p {
        overflow-wrap: break-word;
        text-align: left;
        font-family: 'Arimo';
        line-height: 1.3rem;
        letter-spacing: 0.1rem;
        font-size: 0.925rem;
      }
      }
      h4 {
        text-align: left;
        line-height: 2.5rem;
        letter-spacing: 0.1rem;
      }
      li {
        text-align: left;
        line-height: 1.3rem;
      letter-spacing: 0.1rem;
      font-family: 'Arimo';
        font-size: 0.925rem;
        margin: 0 5vw;
      }

  @media screen and (min-width: 280px) and (max-width: 720px) {
    margin: 0 5vw;
    .search{
      span{
        display: none;
      }
    }
    .work{
    .works{
      grid-template-columns: repeat(1, 1fr);
      .workDesc {
        img{
          width:10vh;
          min-width: 6vh;
        }
      }
  }
}
}
  @media screen and (min-width: 720px) and (max-width: 1080px) {
    margin: 0 10vw;
    .search{
      span{
        display: none;
      }
    }
    .work{
    .works {
      grid-template-columns: repeat(1, 1fr);
      .workDesc {
        img{
          width:12vh;
          min-width: 8vh;
        }
      }
    }
  }
  }
`;