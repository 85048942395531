import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import About from "./components/About";
import Contacts from "./components/Contacts";
import Home from "./components/Home";
import Navbar from "./components/Navbar";
import Press from "./components/Press";
import ScrollToTop from "./components/ScrollToTop";
import Works from "./components/Works";
import scrollreveal from "scrollreveal";

function App() {

  useEffect(() => {
    const sr = scrollreveal({
      origin: "top",
      distance: "80px",
      duration: 2000,
      reset: false,
    });
    sr.reveal(
      `
        nav,
        #home,
        #about,
        #work,
        #press,
        #contact,
        section
    `,
      {
        opacity: 0,
        interval: 200,
      }
    );
  }, []);

  return (
    <Router>
      <ScrollToTop/>
      <Navbar/>
      <Routes>
      <Route path="/">
          <Route index  element={<Home/>}/>
          <Route path="/about" element={<About title="abouts" />}/>
          <Route path="/work" element={<Works title="works" />}/>
          <Route path="/press" element={<Press title="presses" />}/>
          <Route path="/contact" element={<Contacts title="contact_pages" />}/> 
        </Route>
      </Routes> 
  </Router>
  );
}

export default App;
