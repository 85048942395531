import React, {  useState,useEffect } from "react";
import styled from "styled-components";
import {FaCaretRight } from "react-icons/fa";
// import { homePress } from '../services/homeDatas';
import { imageZoomEffect, TitleStyles } from "./ReusableStyle";
import { useNavigate } from 'react-router-dom';
import { Image } from 'antd';
import { baseUrl } from "../services/envhttp";
import { listApi } from "../services/appApi";

function HomePress({title}) {
  let navigate=useNavigate();
  const handleClick = (e) => navigate('/press');
  const [query,setQuery]= useState([]);
  const [visible, setVisible] = useState(false);
  const [imgSrc, setImage] = useState(null);
  const handleImgView = (img) => {
    setVisible(true);
    setImage(img);
  }

  useEffect(() => {

    const getItems = async () => {
      try {
        await listApi(title).then((response)=>{
            if(response.data.length>0){
              setQuery(response.data);
            }else{
              setQuery("");
             }	
            })
      } catch (err) {
        console.log(err);
      }
    }
    getItems();
  }, [title]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Section>
    <div className="title">
            <h1>
                <span>press</span>
            </h1>
        </div>
        <div className="press">
        {query.map((press) => {
            return (
                <div className="pressDetails" key={press.id}>
                    <div className="image">
                    <img src={baseUrl + "/public"+ press.img} alt=""/>
                        <Image
                    width="100%"
                    height="100%"
                    style={{
                      display: 'none',
                    }}
                    preview={{
                      visible,
                      src: imgSrc,
                      onVisibleChange: (value) => {
                        setVisible(value);
                      },
                    }}
                  />  
                    </div>
                    <h4>{press.title}</h4>
                    <p>{press.info}</p>
                    <button onClick={() => handleImgView(baseUrl + "/public"+ press.img)} >Read More <FaCaretRight style={{color: "#e85d04"}} /></button>
                </div>
            );
        })}
        </div>
        <button className='btn' onClick={handleClick}>View All Press</button>
    </Section>
  )
}

export default HomePress;

const Section = styled.section`
  ${TitleStyles};
  ${imageZoomEffect};

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 3rem;
    padding-top: 1rem;
  .press {
    margin-top: 3rem;
    display: grid;
    grid-template-columns:  repeat(3, 1fr);
    gap: 3rem;
    justify-content: left;
    align-items: left;
    text-align:left;

   .pressDetails{
    justify-content: left;
     align-items: left;
     text-align:left;
    
    .image {
        max-height: 30rem;
        overflow: hidden;
        border-radius: 1rem;
        max-width: 99%;
        img {
            width: 99%;
          object-fit: cover;
        }
    }
    h4{
      padding: 0.5rem 2rem;
        font-size: 1.5375rem;
        letter-spacing: 0.01rem;
    }
    p{
      padding: 0.5rem 2rem;
        font-family: 'Arimo', sans-serif;
        font-size: 1.0625rem;
        letter-spacing: 0.1rem;
      line-height: 1rem;
    }

    button {
      border: none;
      padding: 0.5rem 2rem;
      font-size: 1.0813rem;
      font-weight: 600;
      color: black;
      margin-top: 1rem;
      margin-bottom: 1rem;
      letter-spacing: 0.1rem;
      line-height: 1.6rem;
      border-radius: 2rem;
      transition: 0.5s ease-in-out;
      cursor: pointer;
      background:none;
      text-transform: uppercase;
   }
  }
}
  .btn {
    border: none;
    padding: 0.5rem 0.4rem;
    font-size: 1.2437rem;
    font-weight: 600;
    margin-top: 1rem;
    margin-bottom: 5rem;
    transition: 0.5s ease-in-out;
    cursor: pointer;
    background:none;
    border-bottom: solid black;
    text-transform: uppercase;
    letter-spacing: 0.2rem;
    line-height: 1.5rem;
 }

  @media screen and (min-width: 280px) and (max-width: 720px) {
    margin: 0 5vw;
    .press {
      grid-template-columns: repeat(1, 1fr);
    }
  }
  @media screen and (min-width: 720px) and (max-width: 1080px) {
    margin: 0 10vw;
    .press{
    grid-template-columns:  repeat(2, 2fr);
    }
   }
  `;