import React, { useState,useEffect } from "react";
import styled from "styled-components";
import parse from 'html-react-parser';
import contactImg from "../assets/icon/contact.png";
import scrollreveal from "scrollreveal";
import { listApi } from "../services/appApi";

function Contacts({title}) {

  const [query,setQuery]= useState([]);

  useEffect(() => {
    const getItems = async () => {
      try {
        await listApi(title).then((response)=>{
            if(response.data.length>0){
              setQuery(response.data);
            }else{
              setQuery("");
             }	
            })
      } catch (err) {
        console.log(err);
      }
    }
    getItems();
    const sr = scrollreveal({
      origin: "top",
      distance: "80px",
      duration: 2000,
      reset: false,
    });
    sr.reveal(
      `
        #contact
    `,
      {
        opacity: 0,
        interval: 200,
      }
    );
  }, [title]);

  return (
    <Section id="contact">
              <div className="title">
                        <h1>
                            <span>CONTACT</span>
                        </h1>
                    </div>
               <div className="image">
                  <img src={contactImg} alt="" />
                </div>
            {query.map((contact) => {
                    return (
                        <div className="details">
                           <p>{parse(contact.line_1)}</p> 
                           <p>{parse(contact.line_2)}</p> 
                        </div>
                    );
                })}
            
        </Section>
  )
}

export default Contacts;

const Section = styled.div`

display: flex;
flex-direction: column;
position: relative;
top:-20px;
width: 100%;
gap: 3rem;
font-family: 'Lora', serif;
background-color:lightgray;
justify-content: center;
align-items: center;
text-align:left;
padding-top: 10rem;

.title{
  display: flex;
 flex-direction: column;
 margin-top: 4rem;
 gap: 2.6rem;
 justify-content: center;
 align-items: center;
 text-align:center;
 h1 {
  font-size: 1.4187rem;
}
}

.image {
  overflow: hidden;
  border-radius: 1rem;
  max-height:20vh
  img {
    height: 20vh;
    object-fit: cover;
  }
}
.details {
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  margin-bottom: 3rem;
  font-size:1.4187rem;
  padding: 0 5vw;
  overflow-wrap: break-word;
}


@media screen and (min-width: 280px) and (max-width: 720px) {
  align-items:unset;

    .details {
      max-width:100%;
      gap: 1.1rem;
    }

}
@media screen and (min-width: 720px) and (max-width: 1080px) {
  align-items:unset;

    .details {
      max-width:100%;
      gap: 1.1rem;
    }

 }
`;