//import React, { useEffect } from "react";
import Hero from './Hero';
import HomeAbout from './HomeAbout';
import HomeIcons from './HomeIcons';
import HomeWork from './HomeWork';
import HomeTestimonial from './HomeTestimonial';
import HomePress from './HomePress';
import HomeContact from './HomeContact';
//import scrollreveal from "scrollreveal";

function Home() {
 /* useEffect(() => {
    const sr = scrollreveal({
      origin: "top",
      distance: "80px",
      duration: 2000,
      reset: false,
    });
    sr.reveal(
      `
        #home,
        section
    `,
      {
        opacity: 0,
        interval: 200,
      }
    );
  }, []); */
  
    return (
        <div>
          <Hero title="banners"/>
          <HomeAbout title="home_abouts"/>
          <HomeIcons title="home_icons"/>
          <HomeWork title="home_works"/>
          <HomeTestimonial title="home_testimonials"/>
          <HomePress title="home_presses"/>
          <HomeContact title="contacts"/>
        </div>
    )
}

export default Home;