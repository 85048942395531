import React, { useState,useEffect  } from "react";
//import { homeIcons } from '../services/homeDatas';
import styled from "styled-components";
import { listApi } from "../services/appApi";
import { baseUrl } from "../services/envhttp";

function HomeIcons({title}) {

  const [query,setQuery]= useState([]);

  useEffect(() => {
    const getItems = async () => {
      try {
        await listApi(title).then((response)=>{
            if(response.data.length>0){
              setQuery(response.data);
            }else{
              setQuery("");
             }	
            })
      } catch (err) {
        console.log(err);
      }
    }
    getItems();
  },[title]); 

  return (
    <Section>
      {query.map((icon) => {
        return (
          <div className="icons" key={icon.id}>
            <div className="iconImage">
            <img src={baseUrl + "/public"+ icon.icon} alt=""/>
              <p>{icon.info}</p>
            </div>
          </div>
        );
      })}
    </Section>
  )
}

export default HomeIcons;

const Section = styled.section`

    display: grid;
    grid-template-columns:  repeat(3, 1fr);
    gap: 1rem;
    margin-top: 1rem;
    text-align:center;
    font-size: 1.2437rem;

    .icons {
        display: flex;
        flex-direction: column;
        gap: 0.6rem;
        letter-spacing: 0.3rem;
        line-height: 1.6rem;
      font-weight: 600;
        justify-content: center;
        align-items: center;
        text-align:center;
        max-width:100%;
    }
 
  @media screen and (min-width: 280px) and (max-width: 720px) {
    margin: 0 5vw;
    grid-template-columns:  repeat(1, 1fr);
  }
  @media screen and (min-width: 720px) and (max-width: 1080px) {
    margin: 0 10vw;
    grid-template-columns:  repeat(2, 1fr);
  }
`;