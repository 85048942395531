import { css } from "styled-components";
export const imageZoomEffect = css`
  img {
    transition: 0.8s ease-in-out;
    &:hover {
      transform: scale(1.2);
    }
  }
`;

export const TitleStyles = css`
  .title {
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 2rem;
   
    h1 {
      font-size: 2.25rem;
      font-family: 'Josefin Sans', sans-serif;
     font-weight:700;
      
      span {
        color:  rgb(182, 132, 67);
      }
    }
    .yellow {
      color: #f9c74f;
    }
    p {
      padding: 0 10vw;
      font-size: 1.0625rem;
      line-height: 2rem;
      letter-spacing: 0.1rem;
    }
  }
  
  @media screen and (min-width: 260px) and (max-width: 1080px) {
    .title {
      gap: 1rem;
      p {
        padding: 0 1vw;
      }
    }
  }
`;