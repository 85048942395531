import React, { useState,useEffect } from "react";
import styled from "styled-components";
// mport { contactsDetails } from '../services/contactDatas';
import scrollreveal from "scrollreveal";
import { listApi } from "../services/appApi";

function HomeContact({title}) {

  const [query,setQuery]= useState([]);

  useEffect(() => {
    const getItems = async () => {
      try {
        await listApi(title).then((response)=>{
            if(response.data.length>0){
              setQuery(response.data);
            }else{
              setQuery("");
             }	
            })
      } catch (err) {
        console.log(err);
      }
    }
    getItems();
    const sr = scrollreveal({
      origin: "top",
      distance: "80px",
      duration: 2000,
      reset: false,
    });
    sr.reveal(
      `
        #homeContact
    `,
      {
        opacity: 0,
        interval: 200,
      }
    );
  },[title]);

  return (
    <Section id="homeContact">
                <div className="title">
                        <h1>
                            <span>CONTACT</span>
                        </h1>
                    </div>
                {query.map((contact) => {
                        return (
                            <div className="details" key={contact.id}>
                               <p>{contact.email}</p> 
                               <p>{contact.insta}</p> 
                               <p>{contact.twiter}</p> 
                               <p>{contact.facebook}</p> 
                            </div>
                        );
                    })}
              
        </Section>
  )
}

export default HomeContact;

const Section = styled.div`

display: flex;
flex-direction: column;
width: 100%;
gap: 3rem;
background-color:lightgray;
justify-content: center;
align-items: center;
text-align:left;
.title{
    display: flex;
   flex-direction: column;
   margin-top: 3rem;
   gap: 2.6rem;
   letter-spacing: 0.1rem;
      line-height: 1.8rem;
   justify-content: center;
   align-items: center;
   text-align:center;
   h1{
    font-size: 1.775rem;
   }
   h3 {
    color: #f9c74f;
  }
}

.details {
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
    margin-bottom: 3rem;
    font-family: 'Arimo';
    padding: 0 5vw;
    overflow-wrap: break-word;
    p{
      font-size:1.1187rem;
      letter-spacing: 0.1rem;
      line-height: 1.8rem;
    }
    
}

@media screen and (min-width: 280px) and (max-width: 720px) {
  align-items:unset;

    .details {
      max-width:100%;
      padding: 0 3vw;
    }

}
@media screen and (min-width: 720px) and (max-width: 1080px) {
  align-items:unset;

    .details {
      max-width:100%;
      padding: 0 4vw;
    }

 }

`;