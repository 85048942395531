import React, { useState,useEffect } from "react";
import styled from "styled-components";
// import { homeTestimonials } from '../services/homeDatas';
import { FaStar } from "react-icons/fa";
import scrollreveal from "scrollreveal";
import { listApi } from "../services/appApi";
import parse from 'html-react-parser';

function HomeTestimonial({title}) {

  const [query,setQuery]= useState([]);
  
  useEffect(() => {

    const getItems = async () => {
      try {
        await listApi(title).then((response)=>{
            if(response.data.length>0){
              setQuery(response.data);
            }else{
              setQuery("");
             }	
            })
      } catch (err) {
        console.log(err);
      }
    }
  
    getItems();
    const sr = scrollreveal({
      origin: "top",
      distance: "80px",
      duration: 2000,
      reset: false,
    });
    sr.reveal(
      `
        #homeTestimonial
    `,
      {
        opacity: 0,
        interval: 200,
      }
    );
  },[title]);

  return (
    <Section id="homeTestimonial">
      <div className="title">
        <h3>
          <span><FaStar /><FaStar /><FaStar /><FaStar /><FaStar /></span>
        </h3>
        <h2>
          <span>TESTIMONIALS</span>
        </h2>
      </div>
      {query.map((testmonial) => {
        return (
          <div className="details" key={testmonial.id}>
            {parse(testmonial.desc_1)}

            <p>
             <b> {testmonial.name} </b> {testmonial.dest}
            </p>
          </div>
        );
      })}
    </Section>
  )
}

export default HomeTestimonial;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 3rem;
  margin-top: 3rem;
  background-color:lightgray;
  justify-content: center;
  align-items: center;
  .title{
      display: flex;
     flex-direction: column;
     margin-top: 3rem;
     letter-spacing: 0.1rem;
      line-height: 1.8rem;
     gap: 2.6rem;
     justify-content: center;
     align-items: center;
     text-align:center;
    
     h3 {
      font-size: 1.5375rem;
      color: #f9c74f;
    }
    h2 {
      font-family: 'Josefin Sans';
      font-size: 1.5375rem;
      font-weight:700;
    }
  }

  .details {
      display: flex;
      flex-direction: column;
      gap: 1.6rem;
      font-size: 1.0625rem;
      letter-spacing: 0.1rem;
      line-height: 1.8rem;
      padding: 0 21vw;
      font-family: 'Arimo';
      margin-bottom: 3rem;
      justify-content: center;
      align-items: center;
      p{
        font-family: 'Josefin Sans', sans-serif;
        font-size: 1.125rem;
        letter-spacing: 0.1rem;
        line-height: 1.8rem;
      }
  }

  @media screen and (min-width: 280px) and (max-width: 720px) {
    
    align-items:unset;
    margin-bottom: 3rem;
    .details {
      max-width:100%;
      padding: 0 5vw;
      p{
        font-size: 0.7rem;
        letter-spacing: 0.01rem;
        line-height: 1.5rem;
      }
    }
  }
  @media screen and (min-width: 720px) and (max-width: 1080px) {
    align-items:unset;
    margin-bottom: 3rem;
    .details {
      max-width:100%;
      padding: 0 10vw;
    }
   }
`;