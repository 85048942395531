import React, { useState,useEffect  } from "react";
import styled from "styled-components";
// import { homeWorks } from '../services/homeDatas';
import {  TitleStyles } from "./ReusableStyle";
import { FaCaretRight } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';
import { baseUrl } from "../services/envhttp";
import { listApi } from "../services/appApi";

function HomeWork({title}) {
  let navigate=useNavigate();
  const handleClick = (e) => navigate('/work');
  const [query,setQuery]= useState([]);
  const [work,setWork]= useState([]);
 // const [content,setContent]=useState("");
  const handleGenre = (e) => {
    setQuery(work.filter(home=>home.info.toLowerCase().includes(e.target.value.toLowerCase()))); 
  }

  const handleYear = (e) => {
    setQuery(work.filter(home=>home.year.toLowerCase().includes(e.target.value.toLowerCase()))); 
  }

  const handleTitle = (e) => {
    setQuery(work.filter(home=>home.title.toLowerCase().includes(e.target.value.toLowerCase()))); 
  }

  useEffect(() => {
    const getItems = async () => {
      try {
        await listApi(title).then((response)=>{
            if(response.data.length>0){
              setQuery(response.data);
              setWork(response.data);
            }else{
              setQuery("");
             }	
            })
      } catch (err) {
        console.log(err);
      }
    }
    getItems();
  },[title]); // eslint-disable-line react-hooks/exhaustive-deps

  //console.log(homeWorks.filter(home=>home.info.toLowerCase().includes(query)));
  return (
    <Section>
      <div className="title">
        <h1>
          <span>work</span>
        </h1>
      </div>
      <div className="search">
        <input type="text" placeholder="Search by Genre" onChange={handleGenre} /> <span>|</span>
        <input type="text" placeholder="Search by Year"  onChange={handleYear} /> <span>|</span>
        <input type="text" placeholder="Search by Title"  onChange={handleTitle} />
      </div>
      {query.map((home) => {
        return (
          <div className="work" key={home.id}>
            <div className="image">
              <img src={baseUrl + "/public"+ home.img} alt=""/>
            </div>
            <div className="workDesc">
            <img src={baseUrl + "/public"+ home.icon} alt=""/>              
              <h5>{home.info}</h5>
              <h3>{home.title}</h3>
              <p><b>{home.desc_1}</b></p>
              <p>Produced by: <b>{home.produced_by}</b></p>
              <p>Languages: <b>{home.language}</b></p>
              <button className='workBtn' onClick={handleClick} >Read More <FaCaretRight style={{color: "#e85d04"}}/></button>
            </div>
          </div>
        );
      })}

      <button className='allBtn' onClick={handleClick}>View All Work</button>
    </Section>
  )
}

export default HomeWork;


const Section = styled.section`
  ${TitleStyles};

    text-align:center;
    margin-top: 3rem;
    padding-top: 3rem;
  .search{
    display:flex;
    flex-wrap: wrap;
    margin-top: 2rem;
    gap: 1.6rem;
    justify-content: center;
    align-items: center;
    font-size: 1.1375rem;
    input{
      font-size: 1.1375rem;
      padding: 5px;
  }
  }
.work{
  display: grid;
  grid-template-columns:  50% 50%;
  gap: 1.6rem;
  margin-top: 3rem;

    .image {
      display: grid;
      overflow: hidden;
      border-radius: 1rem;
      max-width: 100%;
      img {
        width: 100%;
       
        object-fit: cover;
      }
    }
  .workDesc {
    display: flex;
    flex-direction: column;
    gap: 0.6rem;
    text-align: left;
    overflow-wrap: break-word;
    img {
      width:15vh;
      min-width: 10vh;
    }
 
    h3 {
      text-align: left;
      font-size: 1.5375rem;
      letter-spacing: 0.1rem;
      line-height: 3.5rem;
    }
    h5 {
      text-align: left;
      font-size: 0.8875rem;
      letter-spacing: 0.3rem;
      line-height: 1.6rem;
      color:  rgb(182, 132, 67);
    }
    p {
      text-align: left;
      font-family: 'Arimo';
      font-size:1.0063rem;
      letter-spacing: 0.1rem;
      line-height: 1rem;
    }
    .workBtn {
      border: none;
      color: black;
      text-align: left;
      font-size: 1.2437rem;
      transition: 0.5s ease-in-out;
      background:none;
      cursor: pointer;
      width: fit-content;
      font-family: 'Arimo';
      font-weight: 600;
      letter-spacing: 0.2rem;
      line-height: 1.6rem;
      text-transform: uppercase;
  }
}   
}
button {
  border: none;
  padding: 0.4rem  0.4rem;
  font-size: 1.2437rem;
  transition: 0.5s ease-in-out;
  color: black;
  margin-top: 2.5rem;
  cursor: pointer;
  background:none;
  width: fit-content;
      font-weight: 600;
      letter-spacing: 0.2rem;
   
  border-bottom: solid black;
  text-transform: uppercase;
}

@media screen and (min-width: 280px) and (max-width: 720px) {
  margin: 0 5vw;
  .search{
    span{
      display: none;
    }
  }
  .work {
    grid-template-columns: repeat(1, 1fr);
    .workDesc {
      img{
        width:10vh;
        min-width: 6vh;
      }  
    }
  }
}
@media screen and (min-width: 720px) and (max-width: 1080px) {
  margin: 0 10vw;
  .search{
    span{
      display: none;
    }
  }
  .work{
  grid-template-columns:  repeat(1, 1fr);
  .workDesc {
    img{
      width:12vh;
      min-width: 8vh;
    }
  }
  }
 }
`;